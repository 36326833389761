import PostHog from 'posthog-react-native'
import {EXPO_PUBLIC_API_KEY_POSTHOG} from '@env'

export let posthog = undefined

export const posthogAsync= PostHog.initAsync(EXPO_PUBLIC_API_KEY_POSTHOG, {
  host: 'https://app.posthog.com'
})

posthogAsync.then(client => {
  posthog = client
})