export const languageDrawerDict = {
  Home: "עַמּוּד הַבַּיִת",
  Settings: "הַגְדָּרוֹת",
  Instructions: "הנחיות הורים",
  Printers: "הַדְפָּסוֹת",
  Statistics: "אזור הורים",
  Subscription: "חֲבִילַת הֲכָנָה",
  coupon: "מימוש קופון",
  "Tell a Friend": "שִׁתּוּף עִם חֲבֵרִים",
  "Sign Out": "הִתְנַתְּקוּת",
  "Available on Whatsapp": "לִיצִירַת קֶשֶׁר בְּוַטצְאַפּ",
};
export const languageSettingsDict = {
  Settings: "הַגְדָּרוֹת",

  Coupon: "קופון",
  Preferences: "תְּצוּגָה",
  Quiz: "תִּרְגּוּלִים",
  "Choose Avatar": "בְּחִירַת דְּמוּת",
  "Use timer": "הַגְבָּלַת זְמַנִּים לְכָל תִּרְגּוּל",
  "Show result during exercise":
    "הַצָּגַת פִּתְרוֹן נָכוֹן מִיָּד לְאַחַר בְּחִירַת תְּשׁוּבָה",
  Save: "שְׁמִירָה",
  "Modified successfully": "הָעִדְכּוּן בֻּצַּע בְּהַצְלָחָה",
  "Modified failed": "הָעִדְכּוּן נִכְשַׁל",
  premium: "פְּרָטֵי מָנוּי",
  status: "סְטָטוּס",
  "start time premium": "תְּחִלַּת מָנוּי",
  "end time premium": "סִיּוּם מָנוּי",
};

export const languagePrintPaperDict = {
  "Print Papers": "חוֹמְרִים לְהַדְפָּסָה",
  "here you can download material and print it":
    "לְמִי שֶׁרוֹצֶה נִתָּן לְהַדְפִּיס אֶת המִּבְחָנִים וּלְתַרְגֵּל בְּקַלּוּת.",
  comprehension: "הֲבָנַת הַנִּקְרָא",
  testsExample: "מִבְחָנִים לְדוּגְמָה",
  tests: "מִבְחָנִים לִמְנוּיִים",
  test1free: "מִבְחָן 1 לְהַמְחָשָׁה",
  test2free: "מִבְחָן 2 לְהַמְחָשָׁה",
  test3free: "מִבְחָן 3 לְהַמְחָשָׁה",
  test1: "מִבְחָן 1",
  test2: "מִבְחָן 2",
  test3: "מִבְחָן 3",
  test4: "מִבְחָן 4 - מִשְׂרַד הַחִנּוּךְ",
  test5: "מִבְחָן 5 - מִשְׂרַד הַחִנּוּךְ",
  "article 21-40": "סִפּוּרֵי הֲבָנַת הַנִּקְרָא - חֵלֶק ב'",
};

export const languageStatisticDict = {
  Statistics: "חוֹמְרִים לְהַדְפָּסָה",
  "explain on statistics":
    "בְּמָסָךְ זֶה אֶפְשָׁר לִצְפּוֹת בִּתְּוֹצָאוֹת הַתִּרְגּוּלִים וְלָדַעַת הֵיכָן כְּדַאי שֶׁיַּשְׁקִיעוּ יוֹתֵר וּלְזַהוֹת מְגַמַּת הִשְׁתַּפְּרוּת.",
  "the user had not exercise this topic": "טֶרֶם בּוּצַע תִּרְגוּל בְּפֶרֶק 😳",
  exercise: "תִּרְגּוּל",
  exam: "מִבְחָן",
};

export const languageSubscriptionDict = {
  "select a subscription": "בְּחִירַת תְּקוּפָה",
  Price: "מחיר",
  days: "יָמִים",
  Choose: "בְּחִירָה",
};

export const languagePremiumModalDict = {
  "Access only for premium member":
    "הַגִּישָׁה לְתִרְגּוּל הִיא רַק לְמִי שֶׁרָכַשׁ חֲבִילַת הֲכָנָה",
  "convince me to buy your thing":
    "לֹא מַרְגִּישִׁים מוּכָנִים עֲדַיִן לְבַצֵּעַ רְכִישָׁה שֶׁל חֲבִילַת הַהֲכָנָה? מֻזְמָנִים לְתַרְגֵּל אֶת הַתִּרְגּוּלִים שֶׁיֵּשׁ בְּחִינָם.",
  "See details": "חֲבִילוֹת הַהֲכָנָה",
};

export const languageScoreModalDict = {
  "Restart quiz": "תִּרְגּוּל חוֹזֵר",
  "See result": "צְפִיָּה בְּפִתְרוֹן",
  "Back to exercise": "חֲזָרָה לְתִרְגּוּלִים",
  "Well done for your work!": "הַתִּרְגּוּל הֻשְׁלַם בְּהַצְלָחָה!",
  "Congratulations!": "צִיּוּן יָפֶה, כָּל הַכָּבוֹד!",
};

export const languageLeaveExerciseModelDict = {
  "Leave page": "יְצִיאָה מֵהַתִּרְגּוּל",
  "your exercise will not save if  you will decide to leave":
    "הֶחְלַטְתָּ לָצֵאת מֵהַתִּרְגּוּל? יֵשׁ לָשִׂים לֵב כִּי הַתְּשׁוּבוֹת שֶׁלְּךָ לֹא יִשְׁמְרוּ לִפְנֵי סִיּוּם הַתִּרְגּוּל.",
  "I agree leave": "חֲזָרָה לִרְשִׁימַת הַתִּרְגּוּלִים",
};

export const languageFeedbackModalDict = {
  Terrible: "גָּרוּעַ",
  Bad: "לֹא טוֹב",
  OK: "בְּסֵדֶר",
  Good: "טוֹב",
  Great: "מְצֻיָּן",
  "give us feedback": "שְׁלִיחַת מָשׁוֹב",
  "fill the title": "נוֹשֵׂא",
  "fill the description": "תֵּאוּר",
  Confirm: "שְׁלִיחָה",
  "We are happy to get feedback":
    "נִשְׂמַח לְקַבֵּל כָּל מָשׁוֹב שֶׁיַּעֲזֹר לְהִשְׁתַּפֵּר",
  "The feedback has been send successfully thanks":
    "הַהוֹדָעָה נִשְׁלְחָה בְּהַצְלָחָה",
  "failed to send the feedback":
    "הַהוֹדָעָה לֹא נִשְׁלְחָה, יֵשׁ לְנַסּוֹת מְאֻחָר יוֹתֵר",
  "please fill the all fields before submission":
    "הַטֹּפֶס לֹא מָלֵא, יֵשׁ לְהַשְׁלִים וּלְנַסּוֹת לִשְׁלֹחַ שֵׁנִית.",
};

export const generalDict = {
  Cancel: "בִּטּוּל",
};

export const languageRulesDict = {
  "is required": "הִינוֹ שְׂדֵה חוֹבָה",
  "should be minimum": "חַיֶּבֶת לְהָכִיל לְפָחוֹת",
  "characters long": "תָּוִים",
  "Email is required": 'דּוֹאַ"ל הִנּוֹ שְׂדֵה חוֹבָה',
  "Email is invalid": 'כְּתֹבֶת הָדּוֹאַ"ל אֵינָהּ תַּקִּינָה',
};

export const languageSignInDict = {
  "Sign in to": "הִתְחַבְּרוּת",
  "Sign up to": "הַרְשָׁמָה",
  MyApp: "מְחוֹנָנִים - שָׁלָב ב'",
  "Get access to your portfolio and more":
    "בְּרוּכִים הַבָּאִים לַהֲכָנָה לְמִבְחֲנֵי קַבָּלָה לִמְחוֹנָנִים",
  "email-address": "דֹּאַר אֵלֶקְטְרוֹנִי",
  Password: "סִיסְמָה",
  "Sign In": "הִתְחַבְּרוּת",
  "Loading...": "טוֹעֵן...",
  "Forgot password?": "שָׁכַחְתִּי סִיסְמָה",
  "Don't have an account? Create one": "יְצִירַת חֶשְׁבּוֹן חָדָשׁ",
  "Email doesn't exist": 'דּוֹאַ"ל זֶה אֵינוֹ קַיָּם בַּמַּעֲרֶכֶת',
  "Password isn't correct": "סִיסְמָה אֵינֶנָּה נְכוֹנָה",
  "Email or password doesn't exist": "אֶחָד הַפְּרָטִים שָׁגוּי",
  "Enter your password again": "יֵשׁ לִרְשֹׁם אֶת הַסִּיסְמָה פַּעַם נוֹסֶפֶת",
  "Validate password": "אִמּוּת סִיסְמָה",
  "Back to signIn": "חֲזָרָה לְהִתְחַבְּרוּת",
  "Password do not match": "הַסִּסְמָאוֹת אֵינָן תּוֹאֲמוֹת",
  "Sign Up": "הַרְשָׁמָה",
  "Regenerate new password": "יְצִירַת סִיסְמָה חֲדָשָׁה",
  "reset password": "אִפּוּס סִיסְמָה",
  "Loading check the email...": 'טוֹעֵן... יֵשׁ לִבְדֹּק אֶת הָדּוֹאַ"ל',
  "reset email send successfully":
    'דּוֹאַ"ל לְאִפּוּס הַסִּסְמָא נִשְׁלַח בְּמֵיְל בְּהַצְלָחָה',
  "by sign up I agree to the": "הַרְשָׁמָה זֹאת מְהַוָּה אִשּׁוּר",
  terms: "לִתְנָאֵי שִׁמּוּשׁ",
  rtlAndroid:
    "הַכְּתָב מוֹפִיעַ מִשְּׂמֹאל לְיָמִין? בְּהִתְחַבְּרוּת הָרִאשׁוֹנָה יֵשׁ לִסְגֹּר אֶת חַלּוֹן הָאַפְּלִיקַצְיָה וּלְהִכָּנֵס מֵחָדָשׁ וְהַבְּעָיָה  תִּפָּתֵר",
};

export const languageExerciseModalDict = {
  Practice: "תִּרְגּוּל",
  "next question": "שְׁאֵלָה הַבָּאָה",
  "previous question": "שְׁאֵלָה קוֹדֶמֶת",
  "Practice Summary": "סִכּוּם תִּרְגּוּל",
  "finish exercise": "סַיֵּם תִּרְגּוּל",
  "back summary": "חֲזָרָה לְסִכּוּם",
};

export const languagePurchaseDict = {
  "Payment has been successfully completed": "התשלום בוצע בהצלחה",
};
