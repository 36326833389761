import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import {SignInScreen, SignUpScreen } from './../screens'
import ForgotPasswordScreen from '../screens/ForgotPasswordScreen';
import Terms from '../screens/Terms';
import { languageDrawerDict } from '../constant/LanguageDict';


const Stack = createNativeStackNavigator();

const AuthStackNavigator = () => {
  

  return (
    <Stack.Navigator>
      <Stack.Screen name="SignIn" component={SignInScreen} options={{headerShown: false, title: languageDrawerDict["SignIn"]}}/>
      <Stack.Screen name="SignUp" component={SignUpScreen} options={{headerShown: false, title: languageDrawerDict["SignUp"] }}/>
      <Stack.Screen name="ForgotPassword" component={ForgotPasswordScreen} options={{headerShown: false, title: languageDrawerDict["ForgotPassword"]}}/>
      <Stack.Screen name="Terms" component={Terms} options={{headerShown: false, title: languageDrawerDict["Terms"]}}/>
    </Stack.Navigator>
  );
}

export default AuthStackNavigator