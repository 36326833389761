import { React } from "react";
import {
  StyleSheet,
  SafeAreaView,
  ScrollView,
  View,
  Text,
  Image,
  TouchableOpacity,
} from "react-native";
import { useAuth } from "../App";
import { languageDrawerDict } from "../constant/LanguageDict";
import { useQuiz } from "../useContext/useQuiz";
import { Linking } from "react-native-web";

export const list = [
  "- לעשות את הטוב ביותר: יש לכם הזדמנות להעביר מסר משמעותי לילדים במהלך התקופה הקרובה והוא שהם לא תמיד יכולים להצליח במה שהם רוצים להשיג אבל הם יכולים לתת את הכי טוב שלהם וזה מה שחשוב! – לפי דעתנו שיעור זה לחיים חשוב באותה מידה (אם לא יותר) לתוכנית המחוננים.",
  "- הילדים שלכם מוכשרים: מבחן המחוננים איננו מודד את כל המרכיבים שמצביעים על מחוננות ועצם זה שהילד או הילדה לא התקבלו איננו אומר שהם לא מוכשרים מספיק אלא שהבחינה לא נבנתה באופן שיזהה את מחוננות ילדיכם.",
  "- הפחתת הלחץ: תדאגו שההכנה למבחן תתחיל מוקדם (ההמלצה שלנו להתחיל כ-3 חודשים לפני הבחינה), כך ריכוז המאמץ רגע לפני המבחן יהיה נמוך ורגוע יותר דבר שיפחית את הלחץ ביום הבחינה עצמו.",
  "- קבלת ציונים נמוכים: ייתכן כי במהלך התרגולים והמבחנים, הילדים יקבלו ציונים נמוכים כי היו להם שאלות קשות ואז הם גם מוותרים לעצמם. אז עליכם לזכור כי הקבלה לתוכנית המחוננים והמצטיינים היא ביחס לילדים אחרים (בדומה לפסיכומטרי). לכן, אם יש שאלות קשות שלא מצליחים זה לא נורא כי עליהם להצליח רק בקצת מהילד האחר שיושב לידם.",
  "- הכנה מוגזמת: אל תגזימו עם ההכנות לקראת הבחינה מכיוון שקשה מאוד לגרום ל-IQ לעלות בטווח כל כך קצר ולכן העמסת הכנות סביר שתשפיע מעט מאוד, התמקדו בהכנה טובה וסבירה שבעיקר תעלה את המסוגלות הנפשית של ילדכם ותקטין הפתעות.",
  "- בשלות נפשית: לא לכל הילדים יש את הבגרות הנפשית לגשת לבחינה במהלך כיתה ב' ולכן ייתכן ששווה לכם לשקול לדחות את הבחינה מכיתה ב' לכיתה ג' (רמת המבחן קצת יותר קשר) כאשר הילדים בשלים.",
  "- ילד שאיננו רוצה: קשה מאוד להכריח ילד להתכונן לבחינה כאשר הוא לא רוצה וגם לא כל כך אפקטיבי. תוכנית המחוננים והמצטיינים היא אומנם תוכנית מרתקת ומעניינת אך גם מאתגרת ודורשת התמדה ורצון של הילד",
];

export default function InstructionsScreen() {
  const { testVersion } = useQuiz();

  return (
    <SafeAreaView style={{ backgroundColor: "#f6f6f6", direction: "rtl" }}>
      <ScrollView contentContainerStyle={styles.container}>
        <View className="flex-row justify-start" style={styles.header}>
          <Text style={styles.title}>{languageDrawerDict["Instructions"]}</Text>
        </View>
        <View
          style={{ paddingStart: 24, paddingEnd: 24 }}
          className="flex-col justify-start"
        >
          <Text style={styles.subtitle}>שלום הורים יקרים,</Text>
          <Text style={styles.content}>
            אנחנו שמחים ללווות אתכם במהלך תהליך ההכנה של הילדים (הפנייה לבנים
            ובנות) לקראת מבחני המיון לתוכנית מחוננים (ומצטיינים) של משרד החינוך.
          </Text>
          <Text style={styles.content}>
            ממולץ לקרוא את דף ההנחיות והטיפים בתפריט לפני תחילת ההכנה כדי לעזור
            לילדכם כמה שיותר השתדלנו לתמצת בטיפים ולקלוע לעניין אך אם אתם
            מעוניינים בעוד עדכונים, טיפים וחדשות בנוגע למבחן מחוננים ומצטיינים
            אז אתם מוזמנים להצטרף לקבוצת ה-WhatsApp שלנו:
          </Text>
          {!testVersion ? (
            <TouchableOpacity
              onPress={() => {
                Linking.openURL(
                  "https://chat.whatsapp.com/DysJXGAm6OJIBV1KvwVcDw"
                );
              }}
            >
              <Text className="mb-10 text-lg underline p-1 italic color-gray-700 bg-green-400/40 rounded-lg">
                קישור לקבוצה הווצאפ
              </Text>
            </TouchableOpacity>
          ) : null}
          <Text style={styles.content}>
            מבחני מיון אלו יקבעו אילו ילדים יוגדרו כ"בעלי פוטנציאל גבוה" שהמדינה
            רוצה להשקיע בהם תוספת תקציב משמעותית על מנת שיגשימו פוטנציאל זה.
          </Text>
          <Text style={styles.content}>
            ניתן לומר שמדובר באחת הצמתים המשמעותיות והמוקדמות ביותר אשר יכולה
            לנווט את מהלך החיים של הילדים לאפיק חיובי ומוצלח בעתיד.
          </Text>
          <Text style={styles.content}>
            אומנם קצת מלחיץ, אך למרות הלחץ והרצון הרב שלכם שהילדים יצליחו כמה
            שיותר במהלך החיים עליכם לזכור כי ילדים הם ילדים והם יתנהגו כמו
            ילדים!
          </Text>
          <Text style={styles.content}>
            לא תמיד הלחצת הילדים להישגיות והצלחה מניבה תוצאות חיוביות. לעיתים
            קרובות, הלחץ מוביל לתוצאות שליליות ולכן עליכם ההורים למצוא את דרך
            המלך בה מצד אחד הילדים מתכוננים למבחן ומשפרים את הסיכויים ומצד שני
            הם לא נלחצים מהמבחן ומשיגים את התוצאות הטובות ביותר.
          </Text>
          <Text style={styles.content}>
            אז הרשו לנו לספק לכם כמה טיפים שאולי יעזרו לכם לבנות גישה נכונה
            לקראת המבחן:
          </Text>
          {list.map((value) => (
            <Text style={styles.list}>{value}</Text>
          ))}
          <Text style={styles.content}>
            השתדלנו לתמצת בטיפים ולקלוע לעניין אך אם אתם מעוניינים בעוד עדכונים,
            טיפים וחדשות בנוגע למבחן מחוננים ומצטיינים אז אתם מוזמנים להצטרף
            לקבוצת ה-WhatsApp שלנו:
          </Text>
          {!testVersion ? (
            <TouchableOpacity
              onPress={() => {
                Linking.openURL(
                  "https://chat.whatsapp.com/DysJXGAm6OJIBV1KvwVcDw"
                );
              }}
            >
              <Text className="mb-10 text-lg underline p-1 italic color-gray-700 bg-green-400/40 rounded-lg">
                קישור לקבוצה הווצאפ
              </Text>
            </TouchableOpacity>
          ) : null}
          <Text style={styles.content}>מאחלים בהצלחה,</Text>
          <Text style={styles.content}>צוות בית ספר לחיים</Text>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 24,
  },

  header: {
    direction: "rtl",
    paddingStart: 24,
    paddingEnd: 24,
    marginBottom: 12,
  },
  title: {
    direction: "rtl",
    fontSize: 32,
    fontWeight: "700",
    color: "#1d1d1d",
    marginBottom: 6,
  },
  subtitle: {
    fontSize: 18,
    fontWeight: "500",
    color: "#222",
    marginBottom: 2,
  },
  content: {
    fontSize: 16,
    lineHeight: 28,
    fontWeight: "500",
    color: "#222",
  },
  list: {
    marginTop: 2,
    fontSize: 16,
    lineHeight: 28,
    fontWeight: "500",
    color: "#222",
  },
});
