import { Dimensions } from "react-native";
const { width, height } = Dimensions.get("window");

export const COLORS = {
  primary: "#252c4a",
  secondary: "#1E90FF",
  accent: "#3498db",

  success: "#00C851",
  error: "#ff4444",

  black: "#171717",
  white: "#FFFFFF",
  background: "#252C4A",
};

export const SIZES = {
  base: 10,
  width,
  height,
};

export const dictHomeScreenImage = {
  math: require("./../assets/images/math.png"),
  formal_analogies: require("./../assets/images/formal_analogies.png"),
  comprehension: require("./../assets/images/comprehension.png"),
  combined: require("./../assets/images/combined.png"),
};

export const url = {
  pay: "https://payments.supergifted.co.il/pay.php",
  success: "https://payments.supergifted.co.il/success.php",
};
// export const url ={
//   pay:"https://blog.logrocket.com/common-bugs-react-native-scrollview/",
//   success: "https://lp.logrocket.com/blg/signup"
// }
export const animalList = [
  "dog",
  "hippo",
  "horse",
  "cat",
  "dove",
  "dragon",
  "fish",
  "otter",
  "feather",
  "frog",
  "dog",
  "hippo",
  "horse",
  "cat",
  "dove",
  "dragon",
  "fish",
  "otter",
  "feather",
  "frog",
  "dog",
  "hippo",
  "horse",
  "cat",
  "dove",
  "dragon",
  "fish",
  "otter",
  "feather",
  "frog",
];

export const mmlOptions = {
  messageStyle: "none",
  extensions: ["tex2jax.js"],
  jax: ["input/TeX", "output/HTML-CSS"],
  tex2jax: {
    inlineMath: [
      ["$", "$"],
      ["\\(", "\\)"],
    ],
    displayMath: [
      ["$$", "$$"],
      ["\\[", "\\]"],
    ],
    processEscapes: true,
  },
  TeX: {
    extensions: [
      "AMSmath.js",
      "AMSsymbols.js",
      "noErrors.js",
      "noUndefined.js",
    ],
  },
};

//  const data = [
//   {
//     id: "month",
//     title: "1 Month",
//     price: 49,
//     image: "hand-thumb-up",
//     color: "#F0FF42",
//   },
//   {
//     id: "3-month",
//     title: "3 Month",
//     price: 79,
//     image: "trophy",
//     color: "#00C851",
//   },
//   {
//     id: "6-month",
//     title: "6 Month",
//     price: 99,
//     image: "rocket-chat",
//     color: "#3498db",
//   },
// ];

// export const CATEGORYS = [
//    {
//       id:"category_math",
//       name:"חשבון",
//       description:"חיסור חיבור משוואות ונעלמים"
//    },
//    {
//       id:"category_shaps",
//       name:"צורניות",
//       description:"אנלוגיות צורניות"
//    },
//    {
//       id:"category_reading",
//       name:"הבנת הנקרא",
//       description:"הבנת טקסט ודומה לו"
//    },
// ]
// export const CATEGORIES = [
//    {
//       id:"math",
//       name:"חשבון",
//       description:"חיסור חיבור משוואות ונעלמים",
//       iconName:"search.png",
//       exercises:[
//          {id:"math_1",
//          time:160,
//          level:1,
//          isFree: true
//         },
//         {id:"",
//          time:160,
//          level:1,
//          isFree: true,
//          more_details:"rererer"
//         },
//         {id:3,
//          time:160,
//          level:1,
//          isFree: true
//         },
//         {id:4,
//          time:160,
//          level:1,
//          isFree: true
//         },
//         {id:5,
//          time:160,
//          level:1,
//          isFree: true
//         },
//         {id:6,
//          exersise_time:160,
//          level:1,
//          isFree: true
//         }]
//    },
//    {
//       id:"formal_analogies",
//       name:"צורניות",
//       description:"אנלוגיות צורניות",
//       iconName:"random.png",
//       exercises:[{id:"jkjhkj2",
//       time:160,
//       level:1,
//       isFree: true
//      },
//      {id:"",
//       time:160,
//       level:1,
//       isFree: true,
//       more_details:"rererer"
//      },
//      {id:3,
//       time:160,
//       level:1,
//       isFree: true
//      },
//      {id:4,
//       time:160,
//       level:1,
//       isFree: true
//      },
//      {id:5,
//       time:160,
//       level:1,
//       isFree: true
//      },
//      {id:6,
//       exersise_time:160,
//       level:1,
//       isFree: true
//      }]
//    },
//    {
//       id:"comprehension",
//       name:"הבנת הנקרא",
//       description:"הבנת טקסט ודומה לו",
//       iconName:"search.png",
//       exercises:[{id:"jkjhkj3",
//       time:160,
//       level:1,
//       isFree: true
//      },
//      {id:"",
//       time:160,
//       level:1,
//       isFree: true,
//       more_details:"rererer"
//      },
//      {id:3,
//       time:160,
//       level:1,
//       isFree: true
//      },
//      {id:4,
//       time:160,
//       level:1,
//       isFree: true
//      },
//      {id:5,
//       time:160,
//       level:1,
//       isFree: true
//      },
//      {id:6,
//       exersise_time:160,
//       level:1,
//       isFree: true
//      },]
//    }
// ]

// export const USER_DETAILS ={
//    solutions:{
//       "jkjhkj":
//       {
//          right:5,
//          total:9,
//          answerHistory:{"1231":1,"1232":1,"1233":1,"1234":1,"1235":-1,"1236":3,"1237":1,"1238":2,"1239":2}
//       }
//    }
// }

// export const EXERCISE = {
//    "jkjhkj":{
//       questions: [
//       {
//           question_number:1,
//           id:"1231",
//           questionImage:"",
//           question:"כמה תפוחים תמונה",
//           options:[
//             {option:"right", type:"text", order:0},
//             {option:"wrong", type:"text", order:1},
//             {option:"wrong1", type:"text", order:2},
//             {option:"wrong2", type:"text", order:3}],
//           explanation:"jhfdshkfjshlkfls",
//           explanation_image:""

//        },
//        {
//            question_number:2,
//            id:"1232",
//            questionImage:"",
//            question:"כמה תפוחים תמונה",
//            options:[
//              {option:"right", type:"text", order:0},
//              {option:"wrong", type:"text", order:1},
//              {option:"wrong1", type:"text", order:2},
//              {option:"wrong2", type:"text", order:3}],
//            explanation:"jhfdshkfjshlkfls",
//            explanation_image:""

//         },
//        {
//            question_number:3,
//            id:"1233",
//            questionImage:"",
//            question:"כמה תפוחים תמונה",
//            options:[
//              {option:"right", type:"text", order:0},
//              {option:"wrong", type:"text", order:1},
//              {option:"wrong1", type:"text", order:2},
//              {option:"wrong2", type:"text", order:3}],
//            explanation:"jhfdshkfjshlkfls",
//            explanation_image:""

//         },
//        {
//            question_number:4,
//            id:"1234",
//            questionImage:"",
//            question:"כמה תפוחים תמונה",
//            options:[
//              {option:"right", type:"text", order:0},
//              {option:"wrong", type:"text", order:1},
//              {option:"wrong1", type:"text", order:2},
//              {option:"wrong2", type:"text", order:3}],
//            explanation:"jhfdshkfjshlkfls",
//            explanation_image:""

//         },
//        {
//            question_number:6,
//            id:"1235",
//            questionImage:"",
//            question:"כמה תפוחים תמונה",
//            options:[
//              {option:"right", type:"text", order:0},
//              {option:"wrong", type:"text", order:1},
//              {option:"wrong1", type:"text", order:2},
//              {option:"wrong2", type:"text", order:3}],
//            explanation:"jhfdshkfjshlkfls",
//            explanation_image:""

//         },
//        {
//            question_number:6,
//            id:"1236",
//            questionImage:"",
//            question:"כמה תפוחים תמונה",
//            options:[
//              {option:"right", type:"text", order:0},
//              {option:"wrong", type:"text", order:1},
//              {option:"wrong1", type:"text", order:2},
//              {option:"wrong2", type:"text", order:3}],
//            explanation:"jhfdshkfjshlkfls",
//            explanation_image:""

//         },
//        {
//            question_number:7,
//            id:"1237",
//            questionImage:"",
//            question:"כמה תפוחים תמונה",
//            options:[
//              {option:"right", type:"text", order:0},
//              {option:"wrong", type:"text", order:1},
//              {option:"wrong1", type:"text", order:2},
//              {option:"wrong2", type:"text", order:3}],
//            explanation:"jhfdshkfjshlkfls",
//            explanation_image:""

//         },
//        {
//            question_number:8,
//            id:"1238",
//            questionImage:"",
//            question:"כמה תפוחים תמונה",
//            options:[
//              {option:"right", type:"text", order:0},
//              {option:"wrong", type:"text", order:1},
//              {option:"wrong1", type:"text", order:2},
//              {option:"wrong2", type:"text", order:3}],
//            explanation:"jhfdshkfjshlkfls",
//            explanation_image:""

//         },
//        {
//            question_number:9,
//            id:"1239",
//            questionImage:"",
//            question:"כמה תפוחים תמונה",
//            options:[
//              {option:"right", type:"text", order:0},
//              {option:"wrong", type:"text", order:1},
//              {option:"wrong1", type:"text", order:2},
//              {option:"wrong2", type:"text", order:3}],
//            explanation:"jhfdshkfjshlkfls",
//            explanation_image:""

//         },
//       ]},
// }
