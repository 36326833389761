import React from "react";
import { Helmet } from "react-helmet";

const MetaGoogle = () => {
  return (
    <Helmet>
      <script id="google-web-tracker"
        async
        src="https://www.googletagmanager.com/gtag/js?id=AW-804768539"
      ></script>
      <script id="google-web-tracker2">
        {`window.dataLayer = window.dataLayer || [];  function gtag(){dataLayer.push(arguments);}  gtag('js', new Date());  gtag('config', 'AW-804768539');`}
      </script>
    </Helmet>
  );
};

export default MetaGoogle;
