import React from "react";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { ExerciseScreen, HomeScreen, QuizScreen } from "../screens";
import { createStackNavigator } from "@react-navigation/stack";
import {
  Cog6ToothIcon,
  HomeIcon,
  TagIcon,
  PrinterIcon,
  TrophyIcon,
  DocumentTextIcon,
  BookOpenIcon,
} from "react-native-heroicons/outline";
import CouponScreen from "../screens/CouponScreen";
import InstructionsScreen from "../screens/InstructionsScreen";
import SettingsScreen from "../screens/SettingsScreen";
import CustomDrawer from "../components/CustomDrawer";
import PrintPaperScreen from "../screens/PrintPaperScreen";
import SubscriptionScreen from "../screens/SubscriptionScreen";
import SubscriptionStackScreen from "../screens/SubscriptionStackScreen";
import { useQuiz } from "../useContext/useQuiz";
import { languageDrawerDict } from "../constant/LanguageDict";
import { Platform } from "react-native";
import { Image } from "react-native";
import StatisticsScreen from "../screens/StatisticsScreen";
import OnboardingScreen from "../screens/OnboardingScreen";

const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();

function AppStackNavigator() {
  const { testVersion } = useQuiz();
  const screenOptionsStyle = {
    headerTitleAlign: "center",
    headerShown: false,
    headerStyle: {
      backgroundColor: "#e8ecf4",
    },
    drawerActiveBackgroundColor: "#aa18ea",
    drawerActiveTintColor: "#fff",
    drawerInactiveTintColor: "#333",
    drawerLabelStyle: {
      marginStart: -25,
      // fontFamily: 'Roboto-Medium',
      fontSize: 15,
      textAlign: Platform.OS == "web" ? "right" : "left",
    },
  };
  if (Platform.OS === "ios") {
    screenOptionsStyle["drawerItemStyle"] = { display: "inline-block" };
  }
  return (
    <Drawer.Navigator
      drawerContent={(props) => <CustomDrawer {...props} />}
      screenOptions={screenOptionsStyle}
    >
      <Drawer.Screen
        name="Home"
        component={HomeScreen}
        options={{
          headerShown: true,
          headerTitle: () => (
            <Image
              style={{ width: 172, height: 52 }}
              source={require("./../assets/logo/logo.png")}
            />
          ),
          title: languageDrawerDict["Home"],
          drawerLabel: languageDrawerDict["Home"],
          drawerIcon: ({ color }) => <HomeIcon size={22} color={color} />,
        }}
      />
      {!testVersion && (
        <Drawer.Screen
          name="coupon"
          component={CouponScreen}
          options={{
            headerShown: true,
            title: languageDrawerDict["coupon"],
            drawerLabel: languageDrawerDict["coupon"],
            drawerIcon: ({ color }) => <TagIcon size={22} color={color} />,
          }}
        />
      )}
      <Drawer.Screen
        name="Settings"
        component={SettingsScreen}
        options={{
          headerShown: true,
          title: languageDrawerDict["Settings"],
          drawerLabel: languageDrawerDict["Settings"],
          drawerIcon: ({ color }) => <Cog6ToothIcon size={22} color={color} />,
        }}
      />
      <Drawer.Screen
        name="Instructions"
        component={InstructionsScreen}
        options={{
          headerShown: true,
          title: languageDrawerDict["Instructions"],
          drawerLabel: languageDrawerDict["Instructions"],
          drawerIcon: ({ color }) => <BookOpenIcon size={22} color={color} />,
        }}
      />
      {!testVersion && (
        <Drawer.Screen
          name="Subscription"
          component={SubscriptionScreen}
          options={{
            headerShown: true,
            title: languageDrawerDict["Subscription"],
            drawerLabel: languageDrawerDict["Subscription"],
            drawerIcon: ({ color }) => <TrophyIcon size={22} color={color} />,
          }}
        />
      )}
      {!testVersion && (
        <Drawer.Screen
          name="Printers"
          component={PrintPaperScreen}
          options={{
            headerShown: true,
            title: languageDrawerDict["Printers"],
            drawerLabel: languageDrawerDict["Printers"],
            drawerIcon: ({ color }) => <PrinterIcon size={22} color={color} />,
          }}
        />
      )}
      <Drawer.Screen
        name="Statistics"
        component={StatisticsScreen}
        options={{
          headerShown: true,
          title: languageDrawerDict["Statistics"],
          drawerLabel: languageDrawerDict["Statistics"],
          drawerIcon: ({ color }) => (
            <DocumentTextIcon size={22} color={color} />
          ),
        }}
      />
      {/* <Drawer.Screen  name="Quiz" component={QuizScreen} options={{ headerShown: false , drawerItemStyle:{ height: 0 }}}/> */}
    </Drawer.Navigator>
  );
}

const AppAuthNavigator = () => {
  return (
    <Stack.Navigator initialRouteName="Home">
      {/* https://reactnavigation.org/docs/header-buttons/ for image */}

      <Stack.Screen
        name="Home"
        component={AppStackNavigator}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="הנחיות הורים"
        component={InstructionsScreen}
        options={{ headerShown: true }}
      />
      <Stack.Screen
        name="Exercise"
        component={ExerciseScreen}
        options={{ headerShown: false, title: languageDrawerDict["Exercise"] }}
      />
      <Stack.Screen
        name="SubscriptionStack"
        component={SubscriptionStackScreen}
        options={{
          headerShown: true,
          title: languageDrawerDict["Subscription"],
        }}
      />
      <Stack.Screen
        name="Quiz"
        component={QuizScreen}
        options={{
          title: languageDrawerDict["Quiz"],
          headerShown: false,
          swipeEnabled: false,
          gestureEnabled: false,
        }}
      />
    </Stack.Navigator>
  );
};

export default AppAuthNavigator;
