import {
  Text,
  SafeAreaView,
  TouchableOpacity,
  View,
  FlatList,
  Image,
  Platform,
} from "react-native";
import React, { useEffect } from "react";
import { useSubscription } from "../screens/SubscriptionScreen";
import LottieView from "lottie-react-native";
import { useNavigation } from "@react-navigation/native";
import { useAuth } from "../App";
import { languagePurchaseDict } from "../constant/LanguageDict";
import GoogleEvent from "../utils/meta/googleEvent";

const dictSvg = {
  "hand-thumb-up": require("./../assets/images/hand-thumb-up.png"),
  "rocket-chat": require("./../assets/images/rocket-chat.png"),
  trophy: require("./../assets/images/trophy.png"),
};

const SuccessPurchaseCard = () => {
  const { selected } = useSubscription();
  const {currentUser, authStateChanged} = useAuth()
  const navigation = useNavigation();

  useEffect(() => {
    setTimeout(()=>authStateChanged(currentUser),3000)
  }, [])

  const renderImage = () => {
    if (Platform.OS === "web") {
      return (
        <Image
          source={require("./../assets/images/check.png")}
          resizeMode={"contain"}
          className="w-2/3 h-2/3"
        />
      );
    } else {
      return (
        <LottieView
          autoPlay
          className="w-full h-full"
          style={Platform.OS === "ios" ? { start: 0 } : { start: 0 }}
          source={require("../assets/animation/check.json")}
        />
      );
    }
  };

  

  return (
    <SafeAreaView className="flex-1" style={{ direction: "rtl" }}>
      <GoogleEvent eventName={"purchase-web"} details={selected} send_to="AW-804768539/dHbJCPiy1-cYEJuW3_8C" userName={currentUser.email} />
      <View
        className="justify-center items-center"
        style={Platform.OS === "web" ? { height: 300 } : { height: "33%" }}
      >
        {renderImage()}
      </View>

      <View className="h-full">
          <View className="justify-center items-center mb-5" >
            <Text className="text-xl mb-2">{languagePurchaseDict["Payment has been successfully completed"]}</Text>
            <Text className="text-xl mb-2">מרגע זה הערכה המלאה פתוח ל{selected.days} ימים</Text>
          </View>
          <View
              className="items-center mx-4 mb-2"
              style={Platform.OS == "ios" ? { bottom: 10 } : {}}
            >
              <TouchableOpacity
                onPress={() => navigation.navigate("Home")}
                disabled={!selected}
                className={`${
                  selected ? "bg-blue-700" : "bg-gray-300"
                } py-3 w-full p-15 mx-3 items-center rounded-md`}
              >
                <Text className="text-white text-xl text-center">
                  חזרה לדף הבית 
                </Text>
              </TouchableOpacity>
            </View>
      </View>
    </SafeAreaView>
  );
};

export default SuccessPurchaseCard;

