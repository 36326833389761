import React, { createContext, useContext, useState } from "react";
import { SafeAreaView, View, Text, Image, Platform } from "react-native";
import SubscriptionCard from "../components/SubscriptionCard";
import PurchaseCard from "../components/PurchaseCard";
import { createStackNavigator } from "@react-navigation/stack";
import { useNavigation } from "@react-navigation/native";
import { TouchableOpacity } from "react-native-gesture-handler";
import SuccessPurchaseCard from "../components/SuccessPurchaseCard";
// import LottieView from "lottie-react-native";
// import { AntDesign } from "@expo/vector-icons";

export const SubscriptionContext = createContext();

export const useSubscription = () => {
  return useContext(SubscriptionContext);
};

const SubscriptionScreen = ({ allowBack }) => {
  const [selected, setSelected] = useState(null);
  const Stack = createStackNavigator();
  const navigation = useNavigation();



  return (
    <SubscriptionContext.Provider value={{ selected, setSelected }}>

            <Stack.Navigator>
              <Stack.Screen
                name="Subscription"
                component={SubscriptionCard}
                options={{ headerShown: false }}
                initialParams={{goBack: navigation.goBack, allowBack:allowBack}}
              />
              <Stack.Screen
                name="Purchase"
                component={PurchaseCard}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="SuccessPurchase"
                component={SuccessPurchaseCard}
                options={{ headerShown: false }}
              />
            </Stack.Navigator>
          {/* </View> */}
      {/* </SafeAreaView> */}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionScreen;
