const GoogleEvent = ({ eventName, userName="",send_to="", details = {}}) => {
  let detailsEvent = {
    send_to: send_to,
    transaction_id: userName,
    ...details,
  };
  gtag('event', 'conversion', detailsEvent);

};

export default GoogleEvent;
