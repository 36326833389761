import { initializeApp, firebase } from 'firebase/app';
import { initializeAuth} from "firebase/auth";
import AsyncStorage from '@react-native-async-storage/async-storage';
import {getReactNativePersistence} from 'firebase/auth/react-native';
import { getFirestore } from "firebase/firestore";
import {EXPO_PUBLIC_API_KEY, EXPO_PUBLIC_AUTH_DOMAIN, EXPO_PUBLIC_PROJECT_ID, EXPO_PUBLIC_STORAGE_BUCKET, EXPO_PUBLIC_MESSAGING_SENDER_ID, EXPO_PUBLIC_APP_ID, EXPO_PUBLIC_MEASUREMENT_ID} from '@env'

const firebaseConfig = {
  apiKey: EXPO_PUBLIC_API_KEY,
  authDomain: EXPO_PUBLIC_AUTH_DOMAIN,
  projectId: EXPO_PUBLIC_PROJECT_ID,
  storageBucket: EXPO_PUBLIC_STORAGE_BUCKET,
  messagingSenderId: EXPO_PUBLIC_MESSAGING_SENDER_ID,
  appId: EXPO_PUBLIC_APP_ID,
  measurementId: EXPO_PUBLIC_MEASUREMENT_ID
};

export const FIREBASE_APP = initializeApp(firebaseConfig)
export const FIRESTORE_DB = getFirestore(FIREBASE_APP)
// export const FIREBASE_AUTH = getAuth(FIREBASE_APP)
export const FIREBASE_AUTH = initializeAuth(FIREBASE_APP, { persistence: getReactNativePersistence(AsyncStorage) });




// export const getDoc = async ( docPath) => {
//   const docRef = doc(FIRESTORE_DB, docPath);
//   const doc = await getDoc(docRef)
//   if(doc.exists()){
//     const docObj = JSON.stringify(doc.data())
//     return docObj
//   }
//   return null
// }

export const getUrlStoragePath = (path) =>  `https://firebasestorage.googleapis.com/v0/b/images_gifted_2/o/${path}?alt=media`

export const initAppFirebase = () =>{



}