import { Image, Text, View } from 'react-native'
import React, { useEffect,} from 'react'
import { SafeAreaView } from 'react-native';
import FeaturedRow from '../components/FeaturedRow';
import { useQuiz } from '../useContext/useQuiz';
import { FlatList } from 'react-native-gesture-handler';
import LoadingScreen from './LoadingScreen';


const  HomeScreen = () => {
    const {categories, subscription, getGenericData} = useQuiz()
    useEffect(() => {
      if(!categories ){
        getGenericData()
      }
    }, [categories])

    if(!categories && !subscription ) return <LoadingScreen/>

    return (
      <SafeAreaView  className="flex-1 bg-whiteBackground" style={{direction:"rtl"}}>
        <View className="bg-whiteBackground">
        <FlatList
            data={categories}
            keyExtractor={(item) => item.id}
            renderItem={({
              item: { id, name, description, innerDescription, exercises, iconName, bgColor, order }
            }) => (
              <FeaturedRow
              order={order}
              key={id}
              title={name}
              description={description}
              innerDescription={innerDescription}
              exercises = {exercises}
              iconName = {iconName}
              bgColor = {bgColor}
            />)}/>
        </View>
      </SafeAreaView>
    )
  
}



export default HomeScreen
