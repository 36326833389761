import React, { useRef } from "react";
import {
  StyleSheet,
  SafeAreaView,
  ScrollView,
  View,
  Text,
  TouchableOpacity,
  Platform,
} from "react-native";
import { FontAwesome5 } from "@expo/vector-icons";
import fileDownload from "react-file-download";
import { getUrlStoragePath } from "../firebaseConfig";
import { useNavigation, useRoute } from "@react-navigation/native";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from "react-native-heroicons/outline";
import {
  languagePremiumModalDict,
  languagePrintPaperDict,
} from "../constant/LanguageDict";
import ModalBottom from "../modal/ModalBottom";
import { useAuth } from "../App";

// https://withfra.me/components/settings#settings-2

const SECTIONS1 = [
  {
    header: languagePrintPaperDict["testsExample"],
    items: [
      {
        icon: "download",
        label: languagePrintPaperDict["test1free"],
        filePath: "exam%2Ftest1free.pdf",
        filename: `${languagePrintPaperDict["test1free"]}.pdf`,
      },
      {
        icon: "download",
        label: languagePrintPaperDict["test2free"],
        filePath: "exam%2Ftest2free.pdf",
        filename: `${languagePrintPaperDict["test2free"]}.pdf`,
      },
      {
        icon: "download",
        label: languagePrintPaperDict["test3free"],
        filePath: "exam%2Ftest3free.pdf",
        filename: `${languagePrintPaperDict["test3free"]}.pdf`,
      },
    ],
  },
];
const SECTIONS2 = [
  {
    header: languagePrintPaperDict["tests"],
    items: [
      {
        icon: "download",
        label: languagePrintPaperDict["test1"],
        filePath: "exam%2Ftest1.pdf",
        filename: `${languagePrintPaperDict["test1"]}.pdf`,
      },
      {
        icon: "download",
        label: languagePrintPaperDict["test2"],
        filePath: "exam%2Ftest2.pdf",
        filename: `${languagePrintPaperDict["test2"]}.pdf`,
      },
      {
        icon: "download",
        label: languagePrintPaperDict["test3"],
        filePath: "exam%2Ftest3.pdf",
        filename: `${languagePrintPaperDict["test3"]}.pdf`,
      },
      {
        icon: "download",
        label: languagePrintPaperDict["test4"],
        filePath: "exam%2Ftest4.pdf",
        filename: `${languagePrintPaperDict["test4"]}.pdf`,
      },
      {
        icon: "download",
        label: languagePrintPaperDict["test5"],
        filePath: "exam%2Ftest5.pdf",
        filename: `${languagePrintPaperDict["test5"]}.pdf`,
      },
    ],
  },
];

export const PrintPaperScreen = () => {
  const navigation = useNavigation();
  const { currentUser } = useAuth();
  const refRBSheet = useRef();
  const downloadFile = async (fileUrl, name, free) => {
    if (!currentUser?.isPremium && !free) {
      refRBSheet.current.open();
      return;
    }
    const path = getUrlStoragePath(fileUrl);
    if (Platform.OS === "web") {
      fetch(path)
        .then((response) => response.blob())
        .then((blob) => {
          fileDownload(blob, name); // Replace 'example.pdf' with the desired file name
        })
        .catch(
          (error) => console.error("Error downloading file")
          // console.error("Error downloading file:", error)
        );
    }
  };

  return (
    <SafeAreaView style={{ backgroundColor: "#f6f6f6", direction: "rtl" }}>
      <ScrollView contentContainerStyle={styles.container}>
        <View className="flex-row justify-start" style={styles.header}>
          <Text style={styles.title}>
            {languagePrintPaperDict["Print Papers"]}
          </Text>
        </View>
        <View
          style={{ paddingStart: 24, paddingEnd: 24 }}
          className="flex-row justify-start"
        >
          <Text style={styles.subtitle}>
            {
              languagePrintPaperDict[
                "here you can download material and print it"
              ]
            }
          </Text>
        </View>

        {currentUser?.isPremium
          ? null
          : SECTIONS1.map(({ header, items }) => (
              <View style={styles.section} key={header}>
                <View
                  style={styles.sectionHeader}
                  className="flex-row justify-start"
                >
                  <Text style={styles.sectionHeaderText}>{header}</Text>
                </View>
                <View style={styles.sectionBody}>
                  {items.map(({ label, icon, filePath, filename }, index) => {
                    return (
                      <View
                        key={label}
                        style={[
                          styles.rowWrapper,
                          index === 0 && { borderTopWidth: 0 },
                        ]}
                      >
                        <TouchableOpacity
                          onPress={() => downloadFile(filePath, filename, true)}
                        >
                          <View style={styles.row}>
                            <FontAwesome5
                              color="#616161"
                              name={icon}
                              style={styles.rowIcon}
                              size={22}
                            />
                            <Text style={styles.rowLabel}>{label}</Text>

                            <View style={styles.rowSpacer} />
                            <ChevronLeftIcon color="#00CCBB" size={22} />
                          </View>
                        </TouchableOpacity>
                      </View>
                    );
                  })}
                </View>
              </View>
            ))}
        {SECTIONS2.map(({ header, items }) => (
          <View style={styles.section} key={header}>
            <View
              style={styles.sectionHeader}
              className="flex-row justify-start"
            >
              <Text style={styles.sectionHeaderText}>{header}</Text>
            </View>
            <View style={styles.sectionBody}>
              {items.map(({ label, icon, filePath, filename }, index) => {
                return (
                  <View
                    key={label}
                    style={[
                      styles.rowWrapper,
                      index === 0 && { borderTopWidth: 0 },
                    ]}
                  >
                    <TouchableOpacity
                      onPress={() => downloadFile(filePath, filename)}
                    >
                      <View style={styles.row}>
                        <FontAwesome5
                          color="#616161"
                          name={icon}
                          style={styles.rowIcon}
                          size={22}
                        />
                        <Text style={styles.rowLabel}>{label}</Text>

                        <View style={styles.rowSpacer} />
                        <ChevronLeftIcon color="#00CCBB" size={22} />
                      </View>
                    </TouchableOpacity>
                  </View>
                );
              })}
            </View>
          </View>
        ))}
      </ScrollView>
      <ModalBottom
        refRBSheet={refRBSheet}
        title={languagePremiumModalDict["Access only for premium member"]}
        shortText={languagePremiumModalDict["convince me to buy your thing"]}
        image="premium"
        actionText={languagePremiumModalDict["See details"]}
        action={() => {
          navigation.navigate("SubscriptionStack");
          refRBSheet?.current?.close();
        }}
      />
    </SafeAreaView>
  );
};
export default PrintPaperScreen;

const styles = StyleSheet.create({
  container: {
    paddingVertical: 24,
  },
  section: {
    paddingTop: 12,
  },
  sectionHeader: {
    paddingHorizontal: 24,
    paddingVertical: 8,
  },
  sectionHeaderText: {
    fontSize: 14,
    fontWeight: "600",
    color: "#a7a7a7",
    textTransform: "uppercase",
    letterSpacing: 1.2,
  },
  sectionBody: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: "#e3e3e3",
  },
  header: {
    paddingStart: 24,
    paddingEnd: 24,
    marginBottom: 12,
  },
  title: {
    fontSize: 32,
    fontWeight: "700",
    color: "#1d1d1d",
    marginBottom: 6,
  },
  subtitle: {
    fontSize: 15,
    fontWeight: "500",
    color: "#929292",
  },
  profile: {
    padding: 16,
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: "#e3e3e3",
  },
  profileAvatar: {
    width: 60,
    height: 60,
    borderRadius: 9999,
  },
  profileName: {
    marginTop: 12,
    fontSize: 20,
    fontWeight: "600",
    color: "#090909",
  },
  profileEmail: {
    marginTop: 6,
    fontSize: 16,
    fontWeight: "400",
    color: "#848484",
  },
  profileAction: {
    marginTop: 12,
    paddingVertical: 10,
    paddingHorizontal: 16,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#007bff",
    borderRadius: 12,
  },
  profileActionText: {
    marginEnd: 8,
    fontSize: 15,
    fontWeight: "600",
    color: "#fff",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingEnd: 24,
    height: 50,
  },
  rowWrapper: {
    paddingStart: 24,
    backgroundColor: "#fff",
    borderTopWidth: 1,
    borderColor: "#e3e3e3",
  },
  rowIcon: {
    marginEnd: 12,
    marginStart: Platform.OS == "web" ? 12 : 0,
  },
  rowLabel: {
    fontSize: 17,
    fontWeight: "500",
    color: "#000",
  },
  rowValue: {
    fontSize: 17,
    color: "#616161",
    marginEnd: 4,
  },
  rowSpacer: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
  },
});
