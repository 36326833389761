import React from "react";
import { View, Text, TextInput, StyleSheet, Platform } from "react-native";
import { Controller } from "react-hook-form";

const CustomInput = ({
  control,
  name,
  rules = {},
  placeholder,
  subtitle,
  secureTextEntry = false,
  keyboardType = "default",
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <>
          <View style={[{ borderColor: error ? "red" : "#e8e8e8" }]}>
            {subtitle && <Text style={styles.inputLabel}>{subtitle}</Text>}
            <TextInput
              value={value}
              onChangeText={onChange}
              onBlur={onBlur}
              placeholder={placeholder}
              placeholderTextColor="#6b7280"
              style={styles.input}
              secureTextEntry={secureTextEntry}
              keyboardType={keyboardType}
              textAlign={"center"}
            />
          </View>
          {error && (
            <Text
              style={{
                color: "red",
                alignSelf: "stretch",
                textAlign: Platform.OS == "web" ? "right" : "left",
              }}
            >
              {error.message || "Error"}
            </Text>
          )}
        </>
      )}
    />
  );
};

const styles = StyleSheet.create({
  // container: {
  //   backgroundColor: 'white',
  //   width: '100%',
  //   borderColor: '#e8e8e8',
  //   borderWidth: 1,
  //   borderRadius: 5,

  //   paddingHorizontal: 10,
  //   marginVertical: 5,
  // },
  input: {
    height: 44,
    backgroundColor: "#fff",
    paddingHorizontal: 16,
    borderRadius: 12,
    fontSize: 15,
    fontWeight: "500",
    color: "#222",
    textAlign: Platform.OS == "web" ? "right" : "left",
    shadowColor: "#636363",
    shadowOpacity: 0.2,
    shadowRadius: 8,
    elevation: 5,
    shadowOffset: {
      width: 0,
      height: 2,
    },
  },
  inputLabel: {
    fontSize: 17,
    fontWeight: "600",
    color: "#222",
    marginBottom: 8,
    marginTop: 4,
    textAlign: Platform.OS == "web" ? "right" : "left",
  },
});

export default CustomInput;
